import { ColumnFlex, RowFlex } from '@apimmo/front/components/layouts/GridFlex'
import Modal from '@apimmo/front/components/Modal'
import { Tag } from '@apimmo/front/components/Tag/Tag'
import { Text } from '@apimmo/front/components/Typography'
import React from 'react'
import { Grid } from '@apimmo/front/components/layouts/Grid'
import { Button } from '~/components/common/Buttons/Button'
import SignerStatus from './SignerStatus'

type StatusSignatureModalProps = {
  signature?: any,
  open: boolean
  onClose: () => void
}

const StatusSignatureModal = ({
  signature,
  open,
  onClose,
}: StatusSignatureModalProps) => (
    <Modal isOpen={open} onClose={onClose} title="Statut de la signature" size="small">
      <ColumnFlex
        horizontalAlignment="center"
        gap="medium"
      >
        <Text spacingBottom='medium' spacingTop='medium' align="center">
          {signature?.title ? <Tag label={signature.title}/> : null}
        </Text>
        <Grid>
          {signature?.signers?.map((signer: any) => <SignerStatus key={signer.id} signer={signer}/>)}
        </Grid>
        <RowFlex horizontalAlignment="center" gap="xsmall">
          <Button level="secondary" label="Retour" onClick={onClose}/>
        </RowFlex>
      </ColumnFlex>
    </Modal>
  )

export default StatusSignatureModal
