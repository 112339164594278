import { http } from '~/utils/httpClient'
import { ListSignatureSort, SignatureType } from './signatures.constants'
import { Signature, SignatureSigner } from './signatures.types'

export type ListSignaturePayload = {
  page: number,
  search?: string,
  type?: SignatureType,
  sort?: ListSignatureSort,
  collaborateurId?: string,
}

export const listSignature = ({
  page,
  search,
  type,
  sort,
  collaborateurId
}: ListSignaturePayload) => http.$get<void>('signature', {
  params: {
    page,
    search: search || undefined,
    type,
    sort,
    collaborateurId,
  },
})

export type CreateSignaturePayload = {
  files: Blob[],
  type: SignatureType,
  title: string,
  signers: SignatureSigner[],
  renduEstimationId?: string,
  mandatId?: string,
  mandatAvenantId?: string,
  offrePropositionId?: string,
}

export const createSignature = ({
  files,
  type,
  title,
  signers,
  renduEstimationId,
  mandatId,
  mandatAvenantId,
  offrePropositionId,
}: CreateSignaturePayload) => {
  const form = new FormData()

  form.append('type', type)
  form.append('title', title)

  if (renduEstimationId) {
    form.append('renduEstimationId', renduEstimationId)
  }

  if (mandatId) {
    form.append('mandatId', mandatId)
  }

  if (mandatAvenantId) {
    form.append('mandatAvenantId', mandatAvenantId)
  }

  if (offrePropositionId) {
    form.append('offrePropositionId', offrePropositionId)
  }

  files.forEach((file) => {
    form.append('fileContents[]', file)
  })

  signers.forEach((signer) => {
    if (signer.mode) {
      form.append(`signers[${signer.signerableId}]`, signer.mode)
    }
  })

  return http.$post<Signature>(`signature`, form)
}

export const cancelSignature = (signatureId: string) => http.$post<void>(`signature/${signatureId}/cancel`)

export const deleteSignature = (signatureId: string) => http.$delete(`signature/${signatureId}`)

export const downloadSignatureDocument = (signatureId: string) => http.$get<void>(`signature/${signatureId}/document/download`, {
  responseType: 'blob',
})

export const getSignerLink = (signerId: string) => http.$get<Pick<SignatureSigner, 'link'>>(`signature/signer/${signerId}/link`)

export const notifySigner = (signerId: string) => http.$post<SignatureSigner>(`signature/signer/${signerId}/notify`)