import { mediaQueries, spacings } from '@apimmo/front/style/theme'
import { remCalc } from '@apimmo/front/utils/selectors'
import styled from '@emotion/styled'
import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { SideBar } from '~/components/SideBar/SideBar'
import { TopBar } from '~/components/TopBar/TopBar'
import { paths } from '~/routes/routes'
import { selectConnectedUserId } from '~/store/auth/auth.selectors'
import { useFetchConnectedCollaborateur } from '~/store/collaborateurs/collaborateurs.hooks'
import { selectConnectedCollaborateur } from '~/store/collaborateurs/collaborateurs.selectors'
import { useSelector } from '~/store/utils'
import { globalStyle } from '~/style/theme'
import ListSignaturePage from './pages/signature/ListSignaturePage'
import { PdfMandatLocationGestion } from './pages/ProjetVente/Mandat/PdfMandatLocationGestion'

const Agences = lazy(() => import('./pages/Agences/Agences'))
const CollaborateurClients = lazy(() => import('./pages/CollaborateurClients'))
const CollaborateurAgenda = lazy(
  () => import('./pages/Collaborateurs/CollaborateurAgenda'),
)
const Collaborateurs = lazy(
  () => import('./pages/Collaborateurs/Collaborateurs'),
)
const Compromis = lazy(() => import('./pages/Compromis'))
const Error404 = lazy(() => import('./pages/Errors/404'))
const Secteurs = lazy(() => import('./pages/Secteurs'))
const Taches = lazy(() => import('./pages/Taches'))
const Vacances = lazy(() => import('./pages/Vacances'))
const FindContactByPhone = lazy(() => import('./pages/FindContactByPhone'))
const Home = lazy(() => import('./pages/Home'))
const Login = lazy(() => import('./pages/Login/Login'))
const Logout = lazy(() => import('./pages/Logout'))
const ContactPages = lazy(() => import('./pages/ContactPages'))
const Rapprochements = lazy(
  () => import('./pages/Rapprochements/Rapprochements'),
)

const AppWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'sidebar topbar'
    'sidebar content';
  min-height: 100vh;
  grid-template-columns: ${remCalc(216)} minmax(0, 1fr);
  grid-template-rows: max-content 1fr;
`

const AppContentWrapper = styled.div`
  grid-area: content;
  max-width: ${remCalc(globalStyle.appWidth)};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: ${spacings.medium};

  ${mediaQueries.medium} {
    padding: ${spacings.large} ${spacings.medium};
  }
`

const AuthenticatedApp = () => {
  const collaborateur = useSelector(selectConnectedCollaborateur)
  const connectedUserId = useSelector(selectConnectedUserId)
  const { loading } = useFetchConnectedCollaborateur()

  if (loading && !collaborateur && !connectedUserId) return null

  if (!collaborateur && !connectedUserId) {
    return <Redirect to={paths.login} />
  }

  return (
    <AppWrapper>
      <SideBar />
      <TopBar />
      <AppContentWrapper>
        <Suspense fallback={null}>
          <Switch>
            <Route path={paths.home} render={() => <Home />} exact />
            <Route path={paths.showContact} render={() => <ContactPages />} />
            <Route
              path={paths.findContactByPhone}
              render={() => <FindContactByPhone />}
              exact
            />
            <Route path={paths.secteurs} render={() => <Secteurs />} exact />
            <Route
              path={paths.collaborateurs}
              render={() => <Collaborateurs />}
              exact
            />
            <Route
              path={paths.signatures}
              render={() => <ListSignaturePage />}
              exact
            />
            <Route
              path={paths.collaborateurAgenda}
              render={() => <CollaborateurAgenda />}
              exact
            />
            <Route
              path={paths.collaborateurClients}
              render={() => <CollaborateurClients />}
            />
            <Route path={paths.tasks} render={() => <Taches />} exact />
            <Route
              path={paths.rapprochements}
              render={() => <Rapprochements />}
              exact
            />
            <Route path={paths.compromis} render={() => <Compromis />} />
            <Route path={paths.vacances} render={() => <Vacances />} exact />
            <Route path={paths.agences} render={() => <Agences />} exact />
            <Route render={() => <Error404 />} />
          </Switch>
        </Suspense>
      </AppContentWrapper>
    </AppWrapper>
  )
}

export const App = () => (
  <Suspense fallback={null}>
    <Switch>
      <Route path={paths.login} render={() => <Login />} exact />
      <Route path={paths.logout} render={() => <Logout />} exact />
      <AuthenticatedApp />
    </Switch>
  </Suspense>
)
