import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { always, ifElse } from 'ramda'
import { isNilOrEmpty } from 'ramda-adjunct'

export const ucFirst = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const nbSpace = '\xa0'

// @ts-ignore
export const emptyIfFalsyOr = ifElse(isNilOrEmpty, always(''))

export const normalize = (str: string) =>
  str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

export const searchIn = (needle: string, haystack: string): boolean =>
  normalize(haystack).includes(normalize(needle))

export const formatPhone = (phone: string): string => {
  const phoneNumber = parsePhoneNumberFromString(phone, 'FR')

  if (phoneNumber) {
    const formatedPhone =
      phoneNumber.country === 'FR'
        ? phoneNumber.formatNational()
        : phoneNumber.formatInternational()

    return formatedPhone.replace(/ /g, '\u00A0')
  }

  return phone
}

export const normalizePhone = (phone: string): string => {
  const phoneNumber = parsePhoneNumberFromString(phone, 'FR')

  if (phoneNumber) {
    return phoneNumber.number as string
  }

  return phone
}

export const withPluralSuffix = (qty: number, singular: string) =>
  `${singular}${qty > 1 ? 's' : ''}`

export const withQuantityAndPlural = (
  qty: number,
  singular: string,
  feminine = false,
) =>
  `${qty || `Aucun${feminine ? 'e' : ''}`} ${withPluralSuffix(qty, singular)}`

type GetNomCompletParam = {
  civilite?: string | null
  prenom?: string | null
  nom?: string | null
}
export const getNomComplet = (
  { civilite, prenom, nom }: GetNomCompletParam,
  withCivilite = true,
): string => [withCivilite && civilite, prenom, nom].filter(Boolean).join(' ')

export const getFonction = ({ fonction }: { fonction: string }): string =>
  fonction.toUpperCase()

export const genderize = (
  { civilite }: { civilite: string },
  male: string,
  female: string,
): string => (civilite === 'M.' ? male : female)

export const capitalize = (string: string) =>
  `${string.charAt(0).toUpperCase()}${string.slice(1)}`

export const toCamelCase = (str: string) =>
  normalize(str)
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) =>
      idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase(),
    )
    .replace(/\s+/g, '')
