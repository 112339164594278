import { curry, identity } from 'ramda'
import { selectAchatById } from '~/store/achats/achats.selectors'
import { isExistingAchat } from '~/store/achats/achats.utils'
import { selectContactById } from '~/store/contacts/contacts.selectors'
import { RootState } from '~/store/types'
import { selectVisiteById } from '~/store/visites/visites.selectors'
import { ApporteurAffaires } from '../apporteurAffaires/apporteurAffaires.types'
import { Variant } from '../achats/achats.types'
import { selectActiviteEffectueeById } from '../activitesEffectuees/activitesEffectuees.selector'
import { ActiviteEffectuee } from '../activitesEffectuees/activitesEffectuees.types'

export const selectVenteById = curry(
  (venteId: string, state: RootState) => state.ventes.entities[venteId],
)

export const selectVenteByVisiteId = curry(
  (visiteId: string, state: RootState) => {
    const visite = selectVisiteById(visiteId, state)
    if (!visite) return null

    const vente = selectVenteById(visite.vente, state)
    return vente
  },
)

export const selectContactVentes = curry(
  (contactId: string, variant: Variant|undefined, state: RootState) => {
    const contact = selectContactById(contactId, state)
    if (!contact || !contact.ventes) return null

    return contact.ventes
      .map((venteId) => {
        const vente = selectVenteById(venteId, state)
        if (!vente) return null
        return vente
      })
      .filter(identity)
      .filter((vente) => variant ? (vente?.variant ?? Variant.achatvente) === variant : true)
  },
)

export const selectVentesSelectionneesByAchat = curry(
  (achatId: string, state: RootState) => {
    const achat = selectAchatById(achatId, state)

    if (!achat || !isExistingAchat(achat)) return []

    return achat.ventesSelectionnees ?? []
  },
)

export const selectVentesFromIds = curry(
  (venteIds: string[], state: RootState) =>
    venteIds.map((id) => selectVenteById(id, state)),
)

export const selectApporteurAffairesByVenteId = curry(
  (venteId: string, state: RootState): ApporteurAffaires | null => {
    const vente = selectVenteById(venteId, state)

    if (!vente) return null
    return {
      apporteurAffairesId: vente.apporteurAffairesId,
      apporteurAffairesType: vente.apporteurAffairesType,
    }
  },
)

export const selectVenteLastActiviteEffectuee = curry(
  (venteId: string, state: RootState) => {
    const vente = selectVenteById(venteId, state)

    if (!vente || !vente.activitesEffectuees)
      return undefined

    const activites = vente.activitesEffectuees.map((id) =>
      selectActiviteEffectueeById(id, state),
    ) as ActiviteEffectuee[]

    return activites.sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
    )[0]
  },
)
