export enum Permissions {
  updateCollaborateurs = 'Changer les collaborateurs',
  updateConges = 'Changer les congés',
  updateSecteurs = 'Changer les secteurs',
  updateAgences = 'Changer les agences',
  seeCollaborateursClients = 'Voir les clients des collaborateurs',
  seeDeletedClients = 'Voir les clients supprimés',
  seeCollaborateursAgendas = 'Voir les agendas des collaborateurs',
  updateAchatCollaborateur = "Changer le collaborateur d'un achat",
  updateVenteCollaborateur = "Changer le collaborateur d'une vente",
  deleteVenteLegacy = 'Supprimer une vente',
  updateContactProfile = "Peut modifier le profil d'un client",
  deleteContactProfile = "Peut supprimer le profil d'un client",
  delegateRendezVous = 'Peut déléguer un rendez vous',
  deleteMandat = 'Peut supprimer un mandat ou un avenant de mandat',
  updateLockedMandat = 'Peut modifier un mandat validé définitivement',
  reactiverAchat = "Peut réactiver un projet d'achat",
  annulerOffre = 'Peut annuler une offre',
  annulerCompromis = 'Peut annuler un compromis',
  deleteCollaborateurLegacy = 'Peut supprimer un collaborateur',
  filterCollaborateurListVente = 'Peut filtrer sur un collaborateur dans la liste des ventes',
  filterCollaborateurListAchat = 'Peut filtrer sur un collaborateur dans la liste des achats',
  createDecouverteLegacy = "Faire la découverte d'un projet d'achat",
  updateDecouverte = "Modifier la découverte d'un projet d'achat",
  updateAchatContacts = "Modifier les acquéreurs d'un projet d'achat",
  updateAchatDocuments = "Modifier les documents d'un projet d'achat",
  updateAchatFichiers = "Modifier les fichiers d'un projet d'achat",
  createOffreLegacy = 'Faire une offre',
  createOffreProposition = 'Faire une proposition',
  updateOffreLegacy = 'Modifier une offre',
  createSelection = 'Partager un ou plusieurs biens',
  createAchatVisite = "Créer une visite sur un projet d'achat",
  ignorerBien = 'Ignorer un bien',
  createDemandeRenseignements = 'Créer une demande de renseignements',
  createActiviteEffectuee = 'Créer une activité effectuée',
  cloturerAchat = "Peut clôturer un projet d'achat",
  deleteAchatLegacy = 'Supprimer un achat',

  // Roles
  listRole = 'Lister les roles',
  listRoleViaAgences = 'Lister les roles de mes agences',

  // Collaborateurs
  listCollaborateur = 'Lister les collaborateurs', // "Changer les collaborateurs"
  listCollaborateurViaAgences = 'Lister les collaborateurs de mes agences',
  updateCollaborateur = 'Modifier un collaborateur',
  updateCollaborateurViaAgences = 'Modifier un collaborateur de mes agences',
  listCalendar = 'Lister les agendas des collaborateurs', // "Voir les agendas des collaborateurs"
  listCalendarViaAgences = 'Lister les agendas des collaborateurs de mes agences', // "Voir les agendas des collaborateurs"
  deleteCollaborateur = 'Supprimer un collaborateur', // "Peut supprimer un collaborateur"

  listConge = 'Lister les congés', // "Changer les congés"
  listCongeViaAgences = 'Lister les congés de mes agences',
  createConge = 'Créer un congé',
  createCongeViaAgences = 'Créer un congé de mes agences',

  IMPERSONATE_COLLABORATEUR = 'collaborateur/impersonate',

  // Secteurs
  LIST_SECTEUR = 'Lister les secteurs', // "Changer les secteurs"
  LIST_SECTEUR_VIA_AGENCES = 'Lister les secteurs de mes agences',
  CREATE_SECTEUR = 'Créer un secteur',
  UPDATE_SECTEUR = 'Modifier un secteur',
  UPDATE_SECTEUR_VIA_AGENCES = 'Modifier un secteur de mes agences',

  // Agences
  listAgence = 'Lister les agences', // "Changer les agences"
  listAgenceViaAgences = 'Lister mes agences',

  // Contacts
  listContact = 'Lister les contacts',
  listContactDeleted = 'Lister les contacts supprimés', // "Voir les clients supprimés"
  listContactViaClient = 'Lister mes contacts',
  listContactViaCollaborateur = 'Lister les contacts gérés par moi',
  listContactViaAgences = 'Lister les contacts gérés par mes agences', // "Voir les agendas des collaborateurs"
  listContactViaSubalternes = 'Lister les contacts gérés par mes subalternes',
  listContactViaSuperviseurs = 'Lister les contacts gérés par mes superviseurs',
  updateContact = 'Modifier un contact', // "Peut modifier le profil d'un client"
  updateContactViaClient = 'Modifier mon contact',
  updateContactViaCollaborateur = 'Modifier un contact géré par moi',
  updateContactViaAgences = 'Modifier un contact géré par mes agences',
  updateContactViaSubalternes = 'Modifier un contact géré par mes subalternes',
  updateContactViaSuperviseurs = 'Modifier un contact géré par mes superviseurs',
  deleteContact = 'Supprimer un contact', // "Peut supprimer le profil d'un client"
  showContactCoordonnees = "Voir les coordonnées d'un contact",
  showContactCoordonneesViaClient = 'Voir les coordonnées de mon contact',
  showContactCoordonneesViaCollaborateur = "Voir les coordonnées d'un contact géré par moi",
  showContactCoordonneesViaAgences = "Voir les coordonnées d'un contact géré par mes agences",
  showContactCoordonneesViaSubalternes = "Voir les coordonnées d'un contact géré par mes subalternes",
  showContactCoordonneesViaSuperviseurs = "Voir les coordonnées d'un contact géré par mes superviseurs",

  // Ventes
  listVente = 'Lister les ventes',
  listVenteByCollaborateur = 'Lister les ventes par collaborateur', // "Peut filtrer sur un collaborateur dans la liste des ventes"
  listVenteByCollaborateurViaAgences = 'Lister les ventes par collaborateur de mes agences',
  listVenteViaClient = 'Lister mes ventes',
  listVenteViaCollaborateur = 'Lister les ventes gérées par moi',
  listVenteViaAgences = 'Lister les ventes gérées par mes agences',
  listVenteViaSubalternes = 'Lister les ventes gérées par mes subalternes',
  listVenteViaSuperviseurs = 'Lister les ventes gérées par mes superviseurs',
  updateVente = 'Modifier une vente',
  updateVenteViaClient = 'Modifier ma vente',
  updateVenteViaCollaborateur = 'Modifier une vente gérée par moi',
  updateVenteViaAgences = 'Modifier une vente gérée par mes agences',
  updateVenteViaSubalternes = 'Modifier une vente gérée par mes subalternes',
  updateVenteViaSuperviseurs = 'Modifier une vente gérée par mes superviseurs',
  updateVenteCollaborator = 'Modifier le collaborateur sur une vente', // "Changer le collaborateur d'une vente"
  updateVenteCollaboratorViaAgences = 'Modifier le collaborateur sur une vente gérée par mes agences',
  deleteVente = 'Supprimer une vente',
  deleteVenteViaClient = 'Supprimer ma vente',
  deleteVenteViaCollaborateur = 'Supprimer une vente gérée par moi',
  deleteVenteViaAgences = 'Supprimer une vente gérée par mes agences',
  deleteVenteViaSubalternes = 'Supprimer une vente gérée par mes subalternes',
  deleteVenteViaSuperviseurs = 'Supprimer une vente gérée par mes superviseurs',
  deleteMandatOrAvenant = 'Supprimer un mandat ou un avenant de mandat', // "Peut supprimer un mandat ou un avenant de mandat"
  deleteMandatOrAvenantViaAgence = 'Supprimer un mandat ou un avenant de mandat géré par mes agences',
  updateMandatOrAvenant = 'Modifier un mandat ou un avenant de mandat validé définitivement', // "Peut modifier un mandat validé définitivement"
  updateMandatOrAvenantViaAgence = 'Modifier un mandat ou un avenant de mandat validé définitivement géré par mes agences',

  // Achats
  listAchat = 'Lister les achats',
  listAchatByCollaborateur = 'Lister les achats par collaborateur', // "Peut filtrer sur un collaborateur dans la liste des achats"
  listAchatByCollaborateurViaAgences = 'Lister les achats par collaborateur de mes agences',
  listAchatViaClient = 'Lister mes achats',
  listAchatViaCollaborateur = 'Lister les achats gérées par moi',
  listAchatViaAgences = 'Lister les achats gérées par mes agences',
  listAchatViaSubalternes = 'Lister les achats gérées par mes subalternes',
  listAchatViaSuperviseurs = 'Lister les achats gérées par mes superviseurs',
  updateAchat = 'Modifier un achat',
  updateAchatViaClient = 'Modifier mon achat',
  updateAchatViaCollaborateur = 'Modifier un achat géré par moi',
  updateAchatViaAgences = 'Modifier un achat géré par mes agences',
  updateAchatViaSubalternes = 'Modifier un achat géré par mes subalternes',
  updateAchatViaSuperviseurs = 'Modifier un achat géré par mes superviseurs',
  updateAchatCollaborator = 'Modifier le collaborateur sur un achat', // "Changer le collaborateur d'un achat"
  updateAchatCollaboratorViaAgences = 'Modifier le collaborateur sur un achat géré par mes agences',
  deleteAchat = 'Supprimer un achat',
  deleteAchatViaClient = 'Supprimer mon achat',
  deleteAchatViaCollaborateur = 'Supprimer un achat géré par moi',
  deleteAchatViaAgences = 'Supprimer un achat géré par mes agences',
  deleteAchatViaSubalternes = 'Supprimer un achat géré par mes subalternes',
  deleteAchatViaSuperviseurs = 'Supprimer un achat géré par mes superviseurs',
  closeAchat = 'Clôturer un achat',
  closeAchatViaClient = 'Clôturer mon achat',
  closeAchatViaCollaborateur = 'Clôturer un achat géré par moi', // "Peut clôturer un projet d'achat"
  closeAchatViaAgences = 'Clôturer un achat géré par mes agences',
  closeAchatViaSubalternes = 'Clôturer un achat géré par mes subalternes',
  closeAchatViaSuperviseurs = 'Clôturer un achat géré par mes superviseurs',
  uncloseAchat = 'Réactiver un achat',
  uncloseAchatViaClient = 'Réactiver mon achat',
  uncloseAchatViaCollaborateur = 'Réactiver un achat géré par moi', // "Peut réactiver un projet d'achat"
  uncloseAchatViaAgences = 'Réactiver un achat géré par mes agences',
  uncloseAchatViaSubalternes = 'Réactiver un achat géré par mes subalternes',
  uncloseAchatViaSuperviseurs = 'Réactiver un achat géré par mes superviseurs',
  createDecouverte = 'Créer une découverte',
  createDecouverteViaClient = 'Créer une découverte de mon achat',
  createDecouverteViaCollaborateur = 'Créer une découverte sur un achat géré par moi', // "Faire la découverte d'un projet d'achat"
  createDecouverteViaAgences = 'Créer une découverte sur un achat géré par mes agences',
  createDecouverteViaSubalternes = 'Créer une découverte sur un achat géré par mes subalternes',
  createDecouverteViaSuperviseurs = 'Créer une découverte sur un achat géré par mes superviseurs',

  updateAchatDecouverte = 'Modifier la découverte sur un achat', // "Modifier la découverte d'un projet d'achat"
  updateAchatContact = 'Modifier les contacts sur un achat', // "Modifier les acquéreurs d'un projet d'achat"
  updateAchatDocument = 'Modifier les documents sur un achat', // "Modifier les documents d'un projet d'achat"
  updateAchatFichier = 'Modifier les fichiers sur un achat', // "Modifier les fichiers d'un projet d'achat"

  createAchatSelection = 'Créer une sélection de ventes', // "Partager un ou plusieurs biens"
  createAchatRendezvous = 'Créer un rendez-vous sur un achat', // "Créer une visite sur un projet d'achat"
  createAchatDemanderenseignement = 'Créer une demande de renseignements sur un achat', // "Créer une demande de renseignements"
  createAchatActivite = 'Créer une activité effectuée sur un achat', // "Créer une activité effectuée"
  ignoreAchatVente = 'Ignorer une vente sur un achat', // "Ignorer un bien"

  // Offre et compromis
  CREATE_OFFRE = 'Créer une offre',
  CREATE_OFFRE_VIA_COLLABORATEUR = 'Créer une offre sur un achat géré par moi', // "Faire une offre" si je gère l'achat ou la vente
  CREATE_OFFRE_VIA_AGENCES = 'Créer une offre sur un achat géré par mes agences',
  CREATE_OFFRE_VIA_SUBALTERNES = 'Créer une offre sur un achat géré par mes subalternes',
  CREATE_OFFRE_VIA_SUPERVISEURS = 'Créer une offre sur un achat géré par mes superviseurs',
  UPDATE_OFFRE = 'Modifier une offre',
  UPDATE_OFFRE_VIA_COLLABORATEUR = 'Modifier une offre sur un projet géré par moi', // si je gère l'achat ou la vente
  UPDATE_OFFRE_VIA_AGENCES = 'Modifier une offre sur un projet géré par mes agences',
  UPDATE_OFFRE_VIA_SUBALTERNES = 'Modifier une offre sur un projet géré par mes subalternes',
  UPDATE_OFFRE_VIA_SUPERVISEURS = 'Modifier une offre sur un projet géré par mes superviseurs',
  CANCEL_OFFRE = 'Annuler une offre',
  CANCEL_OFFRE_VIA_COLLABORATEUR = 'Annuler une offre sur un projet géré par moi', // "Peut annuler une offre" si je gère l'achat ou la vente
  CANCEL_OFFRE_VIA_AGENCES = 'Annuler une offre sur un projet géré par mes agences',
  CANCEL_OFFRE_VIA_SUBALTERNES = 'Annuler une offre sur un projet géré par mes subalternes',
  CANCEL_OFFRE_VIA_SUPERVISEURS = 'Annuler une offre sur un projet géré par mes superviseurs',
  CREATE_PROPOSITION = 'Créer une proposition',
  CREATE_PROPOSITION_VIA_COLLABORATEUR = 'Créer une proposition sur un projet géré par moi', // "Faire une proposition" si je gère l'achat ou la vente
  CREATE_PROPOSITION_VIA_AGENCES = 'Créer une proposition sur un projet géré par mes agences',
  CREATE_PROPOSITION_VIA_SUBALTERNES = 'Créer une proposition sur un projet géré par mes subalternes',
  CREATE_PROPOSITION_VIA_SUPERVISEURS = 'Créer une proposition sur un projet géré par mes superviseurs',
  CANCEL_COMPROMIS = 'Annuler un compromis',
  CANCEL_COMPROMIS_VIA_COLLABORATEUR = 'Annuler un compromis sur un projet géré par moi', // "Peut annuler un compromis" si je gère l'achat ou la vente
  CANCEL_COMPROMIS_VIA_AGENCES = 'Annuler un compromis sur un projet géré par mes agences',
  CANCEL_COMPROMIS_VIA_SUBALTERNES = 'Annuler un compromis sur un projet géré par mes subalternes',
  CANCEL_COMPROMIS_VIA_SUPERVISEURS = 'Annuler un compromis sur un projet géré par mes superviseurs',

  // Signature
  LIST_SIGNATURE = 'signature/list',
  CREATE_SIGNATURE = 'signature/create',
  SHOW_SIGNATURE = 'signature/show',
  UPDATE_SIGNATURE = 'signature/update',
  CANCEL_SIGNATURE = 'signature/cancel',
  DELETE_SIGNATURE = 'signature/delete',
  MANAGE_SIGNATURE_VIA_COLLABORATEUR = 'signature/manage/collaborateur',
  MANAGE_SIGNATURE_VIA_AGENCES = 'signature/manage/agences',
}
