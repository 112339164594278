import { AwsS3UploadParameters } from '@uppy/aws-s3'
import {
  normalizeContact,
  normalizeContacts,
} from '~/store/contacts/contacts.normalize'
import {
  AddPhoneToContactPayload,
  CollaborateurContactsFetchParams,
  CollaborateurContactsFetchReturn,
  ContactCreationPayload,
  CreateInvitationPayload,
  ContactsFetchParams,
  ContactUpdatePatchPayload,
  ContactUpdatePayload,
  Contact,
} from '~/store/contacts/contacts.types'
import { http } from '~/utils/httpClient'
import { Document } from '../documents/documents.types'
import { Paginated } from '~/utils/types'

export const fetchContactById = async (id: string) => {
  const { contact } = await http.$get(`contacts/${encodeURIComponent(id)}`)
  return normalizeContact(contact)
}

export const fetchCollaborateurContacts = async (
  collaborateurId: string,
  params: CollaborateurContactsFetchParams,
) =>
  http.$get<CollaborateurContactsFetchReturn>(
    `collaborateurs/${collaborateurId}/contacts`,
    { params },
  )

export const fetchContactsDeleted = async (params: ContactsFetchParams) =>
  http.$get<CollaborateurContactsFetchReturn>('contacts/deleted', { params })

export const fetchContactByPhone = async (telephone: string) => {
  const { contact } = await http.$post('contacts/phoneSearch', { telephone })
  return normalizeContact(contact)
}

export const fetchContactsList = async (params: ContactsFetchParams) => {
  const { contacts, meta } = await http.$get<Paginated<{ contacts: Contact[] }>>('contacts', { params })
  return { contacts: normalizeContacts(contacts), meta }
}

export const createContact = async (
  payload: ContactCreationPayload & { creatorId: string | null },
) => {
  const { contact } = await http.$post('contacts', payload)
  return normalizeContact(contact)
}

export const updateContact = async (
  id: string,
  payload: ContactUpdatePayload,
) => {
  const { contact } = await http.$put(`contacts/${id}`, payload)
  return normalizeContact(contact)
}

export const createInvitation = async (
  payload: CreateInvitationPayload,
) => {
  const invitation = await http.$post(`invitation`, payload)
  return invitation
}

export const updateContactPatch = async (
  id: string,
  payload: ContactUpdatePatchPayload,
) => {
  const { contact } = await http.$patch(`contacts/${id}`, payload)
  return normalizeContact(contact)
}

export const addPhoneToContact = async (
  id: string,
  payload: AddPhoneToContactPayload,
) => {
  const { contact } = await http.$post(`contacts/${id}/add-phone`, payload)
  return normalizeContact(contact)
}

export const deleteContact = async (id: string) =>
  http.$delete(`contacts/${id}`)

export const hardDeleteContacts = async (payload: { contactIds: string[] }) =>
  http.$post(`contacts/hard-delete`, payload)

export const getUploadParametersForContactDocumentsUpdate = (
  contactId: string,
  type: string,
) =>
  http.$get<AwsS3UploadParameters>(`contacts/${contactId}/upload-parameters`, {
    params: { type },
  })

export const updateDocumentsOfContact = (
  contactId: string,
  payload: { nom: string; paths: string[] },
) => http.$post<Document>(`contacts/${contactId}/documents`, payload)
