import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { logOut, logUserByFirebase, logUserByIdWhenDisabled, logUserByIdWhenImpersonating } from '~/store/auth/auth.api'
import { useAsync, useAsyncFn } from '~/utils/hooks'
import { logOut as logOutAction, updateCurrentUserId } from './auth.slice'
import { logout } from '~/utils/firebaseClient'

export const useLogOut = () => {
  const dispatch = useDispatch()

  return useAsync(async () => {
    await logout() // Firebase logout
    await logOut() // Papiris logout
    dispatch(logOutAction()) // Local store logout
  }, [])
}

export const useLogInByIdWhenDisabled = () => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const [, doCall] = useAsyncFn(async (userId: string) => {
    const { intendedUrl } = await logUserByIdWhenDisabled(userId)
    dispatch(updateCurrentUserId({ userId }))
    push(intendedUrl)
  }, [])

  return doCall
}

export const useLogInByFirebase = () => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const [, doCall] = useAsyncFn(async (token: string) => {
    const { intendedUrl, userId } = await logUserByFirebase(token)
    dispatch(updateCurrentUserId({ userId }))
    push(intendedUrl)
  }, [])

  return doCall
}

export const useLogInByIdWhenImpersonating = () => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const [, doCall] = useAsyncFn(async (userId: string) => {
    const { intendedUrl } = await logUserByIdWhenImpersonating(userId)
    dispatch(updateCurrentUserId({ userId }))
    push(intendedUrl)
  }, [])

  return doCall
}