import { initializeApp, FirebaseError } from 'firebase/app'
import {
  getAuth,
  signInWithPopup,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendEmailVerification,
} from 'firebase/auth'
import type { User, Unsubscribe, NextOrObserver } from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
}

export const app = initializeApp(firebaseConfig)

export async function signInWithGoogle(): Promise<User|undefined> {
  const provider = new GoogleAuthProvider()

  const auth = getAuth(app)

  auth.useDeviceLanguage()

  try {
    const result = await signInWithPopup(auth, provider)
    const { user } = result

    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result)

    if (credential) {
      // const token = credential.accessToken;
      // The signed-in user info.
      // IdP data available using getAdditionalUserInfo(result)
    }

    return user
  } catch (error) {
    if (error instanceof FirebaseError) {
      throw error
    } else {
      throw new Error('Untyped Firebase error')
    }
  }
}

export async function sendVerificationEmail(user?: User): Promise<void> {
  const auth = getAuth(app)
  const _user = user ?? auth.currentUser

  const actionCodeSettings = {
    url: window.location.href,
  }

  if (_user) {
    sendEmailVerification(_user, actionCodeSettings)
  }
}

export async function createUser(email: string, password: string): Promise<User|undefined> {
  const auth = getAuth(app)

  try {
    const result = await createUserWithEmailAndPassword(auth, email, password)
    const { user } = result

    if (!user.emailVerified) {
      await sendVerificationEmail(user)
    }

    return user
  } catch (error) {
    if (error instanceof FirebaseError) {
      throw error
    } else {
      throw new Error('Untyped Firebase error')
    }
  }
}

export async function signInWithEmail(email: string, password: string): Promise<User|undefined> {
  const auth = getAuth(app)

  try {
    const result = await signInWithEmailAndPassword(auth, email, password)
    const { user } = result

    if (!user.emailVerified) {
      await sendVerificationEmail(user)
    }

    return user
  } catch (error) {
    if (error instanceof FirebaseError) {
      throw error
    } else {
      throw new Error('Untyped Firebase error')
    }
  }
}

export async function resetPassword(email: string): Promise<void> {
  const auth = getAuth(app)

  auth.useDeviceLanguage()

  try {
    await sendPasswordResetEmail(auth, email)
  } catch (error) {
    if (error instanceof FirebaseError) {
      throw error
    } else {
      throw new Error('Untyped Firebase error')
    }
  }
}

export async function changePassword(currentPassword: string, newPassword: string): Promise<void> {
  const auth = getAuth(app)

  try {
    const user = await auth.currentUser

    if (user?.email) {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword,
      )

      await reauthenticateWithCredential(user, credential)
      await updatePassword(user, newPassword)
    }
  } catch (error) {
    if (error instanceof FirebaseError) {
      throw error
    } else {
      throw new Error('Untyped Firebase error')
    }
  }
}

export function subscribeAuthChange(nextOrObserver: NextOrObserver<User | null>): Unsubscribe {
  const auth = getAuth(app)

  return auth.onAuthStateChanged(nextOrObserver)
}

export async function getCurrentUser(): Promise<User | null> {
  const auth = getAuth(app)
  const currentUser = await auth.currentUser

  return currentUser
}

export async function getIdToken(): Promise<string | undefined> {
  const auth = getAuth(app)

  const user = auth.currentUser
  const idToken = await user?.getIdToken(true)

  return idToken
}

export async function logout(): Promise<void> {
  const auth = getAuth(app)

  await signOut(auth)
}
