import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AchatsContactsSearchFilters } from '~/store/achats/achats.types'
import { logOut } from '~/store/auth/auth.slice'
import { VentesContactsSearchFilters } from '~/store/ventes/ventes.types'
import {
  AchatsContactsSearchParams,
  ContactsSearchParams,
  Pagination,
  VentesContactsSearchParams,
} from './searchParams.types'
import { defaultContactPagination } from './searchParams.utils'
import { ContactsSearchFilters } from '../contacts/contacts.types'

export type SearchParamsState = Readonly<{
  contacts: ContactsSearchParams
  ventesContacts: VentesContactsSearchParams
  achatsContacts: AchatsContactsSearchParams
}>

const initialState: SearchParamsState = {
  contacts: {
    pagination: defaultContactPagination,
    searchFilters: null,
  },
  ventesContacts: {
    pagination: defaultContactPagination,
    searchFilters: null,
  },
  achatsContacts: {
    pagination: defaultContactPagination,
    searchFilters: null,
  },
}

const searchParamsSlice = createSlice({
  name: 'searchParams',
  initialState,
  reducers: {
    updateContactsPagination: (
      state,
      { payload }: PayloadAction<{ pagination: Pagination }>,
    ) => {
      state.contacts.pagination = payload.pagination
    },
    updateContactsSearchFilters: (
      state,
      {
        payload: { searchFilters },
      }: PayloadAction<{ searchFilters: ContactsSearchFilters | null }>,
    ) => {
      state.contacts = {
        pagination: defaultContactPagination,
        searchFilters,
      }
    },
    updateVentesContactsPagination: (
      state,
      { payload }: PayloadAction<{ pagination: Pagination }>,
    ) => {
      state.ventesContacts.pagination = payload.pagination
    },
    updateVentesContactsSearchFilters: (
      state,
      {
        payload: { searchFilters },
      }: PayloadAction<{ searchFilters: VentesContactsSearchFilters | null }>,
    ) => {
      state.ventesContacts = {
        pagination: defaultContactPagination,
        searchFilters,
      }
    },
    updateAchatsContactsPagination: (
      state,
      { payload }: PayloadAction<{ pagination: Pagination }>,
    ) => {
      state.achatsContacts.pagination = payload.pagination
    },
    updateAchatsContactsSearchFilters: (
      state,
      {
        payload: { searchFilters },
      }: PayloadAction<{ searchFilters: AchatsContactsSearchFilters | null }>,
    ) => {
      state.achatsContacts.pagination = defaultContactPagination
      state.achatsContacts.searchFilters = searchFilters
    },
  },
  extraReducers: {
    [logOut.type]: () => initialState,
  },
})

export const {
  updateContactsPagination,
  updateContactsSearchFilters,
  updateVentesContactsPagination,
  updateVentesContactsSearchFilters,
  updateAchatsContactsPagination,
  updateAchatsContactsSearchFilters,
} = searchParamsSlice.actions
export const { reducer: searchParamsReducer } = searchParamsSlice
