import styled from '@emotion/styled'
import { spacings, colors, borderRadius } from '@apimmo/front/style/theme'
import { ColumnFlex } from '@apimmo/front/components/layouts/GridFlex'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SelectField } from '../Forms/SelectField'
import { Form } from '../Forms/Form'
import { useFetchCollaborateurs } from '~/store/collaborateurs/collaborateurs.hooks'
import { useLogInByIdWhenImpersonating } from '~/store/auth/auth.hooks'
import { selectCollaborateursAsOptions } from '~/store/collaborateurs/collaborateurs.selectors'

// export type ImpersonateFormProps = {}

export const ImpersonateForm = () => {
  const fetchCollaborateurs = useFetchCollaborateurs()

  useEffect(() => {
    fetchCollaborateurs()
  }, [fetchCollaborateurs])

  const collaborateursOptions = useSelector(selectCollaborateursAsOptions)
  const logIn = useLogInByIdWhenImpersonating()

  const handleSubmit = ({ userId }: { userId: string }) => logIn(userId)
  
  return (
    <FormWrapper>
      <Form initialValues={{ userId: '' }} onSubmit={handleSubmit}>
        <ColumnFlex gap="small" horizontalAlignment="stretch">
          <SelectField name="userId" label="Se connecter en tant que" options={collaborateursOptions} searchable submitOnChange />
        </ColumnFlex>
      </Form>
    </FormWrapper>
  )
}


const FormWrapper = styled.div`
  padding: ${spacings.xsmall} ${spacings.xsmall};
  margin: ${spacings.xsmall} ${spacings.xsmall};
  border-radius: ${borderRadius};
  background: ${colors.white};
`