import { curry, descend, prop, sort } from 'ramda'
import { compact } from 'ramda-adjunct'
import { selectAchatById } from '~/store/achats/achats.selectors'
import { isExistingAchat } from '~/store/achats/achats.utils'
import { RootState } from '~/store/types'
import { selectVenteById } from '~/store/ventes/ventes.selectors'
import { Offre } from './offres.types'
import { selectContactById } from '../contacts/contacts.selectors'
import { Contact } from '../contacts/contacts.types'

export const selectOffreById = curry(
  (offreId: string, state: RootState) => state.offres.entities[offreId],
)

export const selectSortedOffresByAchatId = curry(
  (achatId: string, state: RootState): Offre[] => {
    const achat = selectAchatById(achatId, state)

    if (!achat || !isExistingAchat(achat) || !achat.offres) return []

    return sort(
      descend(prop('createdAt')),
      compact(achat.offres.map((offreId) => selectOffreById(offreId, state))),
    )
  },
)

export const selectSortedOffresByVenteId = curry(
  (venteId: string, state: RootState): Offre[] => {
    const vente = selectVenteById(venteId, state)

    if (!vente || !vente.offres) return []

    return sort(
      descend(prop('createdAt')),
      compact(vente.offres.map((offreId) => selectOffreById(offreId, state))),
    )
  },
)

export const selectContactsByOffreId = curry(
  (
    offreId: string,
    contactType: 'acheteurs' | 'proprietaires',
    state: RootState,
  ): Contact[] => {
    const offre = selectOffreById(offreId, state)

    if (!offre?.[contactType]) return []

    return compact(
      offre[contactType].map((contactId) =>
        selectContactById(contactId, state),
      ),
    ) as Contact[]
  },
)
