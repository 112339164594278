import React, { useEffect, useState } from "react"
import { IconCircleButton } from "@apimmo/front/components/icons/IconCircle"
import { RowFlex } from "@apimmo/front/components/layouts/GridFlex"
import { Text, TextLarge, TextSmall } from "@apimmo/front/components/Typography"
import { getNomComplet } from "@apimmo/front/utils/strings"
import { generatePath } from "react-router"
import { Button } from "~/components/common/Buttons/Button"
import { Link } from "~/components/common/Link/Link"
import { paths } from "~/routes/routes"
import { getSignerLink, notifySigner } from "~/store/signatures/signatures.api"
import { SignatureSigner } from "~/store/signatures/signatures.types"
import { displayDateTimeFromIso } from "~/utils/dates"

export type SignerStatusProps = { signer: SignatureSigner }

export default function SignerStatus({ signer: _signer }: SignerStatusProps) {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<unknown | undefined>()
  const [signer, setSigner] = useState(_signer)

  const signerId = _signer.id

  useEffect(() => {
    setSigner(_signer)
  }, [_signer])

  const handleNotifySigner = async () => {
    setLoading(true)
    setError(undefined)

    try {
      const result = await notifySigner(signerId)
      setSigner(result)
      setSuccess(true)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleOpenSignatureLink = async () => {
    const result: any = await getSignerLink(signerId)

    window.open(result.link, '_blank')
  }

  return (
    <div>
      <TextLarge weight="semibold">
        {signer.status !== 'signed' ? (
          <IconCircleButton
            size="tiny"
            type="button"
            color="transparent"
            onClick={handleOpenSignatureLink}
            icon="link"
            title="Ouvrir le lien de signature"
          />
        ) : null}
        {signer.contactId ? (
          <Link
            underline
            size="large"
            label={getNomComplet({ prenom: signer.firstName, nom: signer.lastName })}
            to={generatePath(paths.showContact, { contactId: signer.contactId })}
          />
        ) : (
          <>
            {getNomComplet({ prenom: signer.firstName, nom: signer.lastName })}
          </>
        )}
      </TextLarge>
      <RowFlex gap="small" verticalAlignment="center" horizontalAlignment="space-between">
        {signer.notifiedAt ? (
          <TextSmall>
            Notifié le {displayDateTimeFromIso(signer.notifiedAt)} ({signer.notifiedCount} fois)
          </TextSmall>
        ) : (
          <TextSmall>
            Pas encore notifié.
          </TextSmall>
        )}
        {signer.status !== 'signed' ? (
          <Button level="hollow" size="tiny" label={signer.notifiedAt ? 'Relancer' : 'Envoyer'} onClick={handleNotifySigner} disabled={loading}/>
        ) : null}
      </RowFlex>
      {signer.openedAt ? (
        <TextSmall>
          Ouvert le {displayDateTimeFromIso(signer.openedAt)}
        </TextSmall>
      ) : null}
      {signer.signedAt ? (
        <TextSmall>
          Signé le {displayDateTimeFromIso(signer.signedAt)}
        </TextSmall>
      ) : null}
      {error ? (
        <Text color="error">
          Une erreur inconnue s‘est produite, merci de réessayer plus tard ou de prévenir l‘administration.
        </Text>
      ) : null}
    </div>
  )
}