import { http } from '~/utils/httpClient'
import { LoginConfig } from './auth.types'

export const fetchLoginConfig = (): Promise<LoginConfig> =>
  http.$get('login/config')

export const logUserByIdWhenDisabled = (
  userId: string,
): Promise<{ intendedUrl: string }> => http.$post('login/disabled', { userId })

export const logUserByIdWhenImpersonating = (
  userId: string,
): Promise<{ intendedUrl: string }> => http.$post('collaborateurs/impersonate', { id: userId })

export const logUserByFirebase = (
  token: string,
): Promise<{ intendedUrl: string, userId: string }> => http.$post('login/firebase', { token })

export const logOut = (): Promise<void> => http.$get('logout')
