import Icon from '@apimmo/front/components/icons/Icon'
import { RowFlex } from '@apimmo/front/components/layouts/GridFlex'
import React, { useMemo } from 'react'
import { ProgressBar } from '~/components/common/ProgressBar/ProgressBar'

export type SignatureProgressBarProps = {
  signature: any;
  variant?: 'default'|'out'
}

function SignatureProgressBar({ signature, variant = 'default' }: SignatureProgressBarProps) {
  const min = 0
  const max = 100
  const now = useMemo(() => {
    if (signature.canceled) {
      return 100
    }

    const signers = signature.signers ?? []
    const countSigners = signers.length

    if (countSigners === 0) {
      return 0
    }

    const countSignedSigners = signers.filter(({status}: any) => status === 'signed').length

    return Math.floor(100 * countSignedSigners / countSigners)
  }, [signature])

  const color = resolveColor(now)
  const label = (
    <RowFlex gap="xsmall" verticalAlignment="center">
      {resolveLabel(now)}
    </RowFlex>
  )

  return <ProgressBar label={label} min={min} max={max} now={now === 0 ? 20 : now} width={variant === 'default' ? '170px' : '120px'} color={color} />

  function resolveColor(now: number) {
    if (signature.canceled) {
      return 'error'
    }
    
    if (now === 0) {
      return 'error'
    }

    if (now === 100) {
      return 'success'
    }

    return 'warning'
  }

  function resolveLabel(now: number) {
    if (signature.canceled) {
      return <>Annulé</>
    }

    if (now === 0) {
      return <>En attente</>
    }

    if (now === 100) {
      return (
        <>
          <Icon color="success" name="check" size={18}/>
          Terminé
        </>
      )
    }

    return <>{now}%</>
  }
}

export default SignatureProgressBar