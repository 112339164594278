import { curry } from 'ramda'
import { RootState } from '~/store/types'
import { selectAchatById } from '../achats/achats.selectors'
import { isExistingAchat } from '../achats/achats.utils'

export const selectVisiteById = curry(
  (visiteId: string, { visites }: RootState) => visites.entities[visiteId],
)

export const selectVisitesByAchatId = curry(
  (achatId: string, state: RootState) => {
    const achat = selectAchatById(achatId, state)

    if (!achat || !isExistingAchat(achat)) return []

    const visitesId = achat.visites

    return visitesId.map((id) => selectVisiteById(id, state))
  },
)

export const selectVisiteByAchatIdAndVenteId = curry(
  (achatId: string, venteId: string, state: RootState) => {
    const visites = selectVisitesByAchatId(achatId, state)

    return visites.find((visite) => visite?.vente === venteId)
  },
)