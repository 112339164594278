import Avatar from '@apimmo/front/components/Avatar'
import Icon from '@apimmo/front/components/icons/Icon'
import { Grid, GridCell } from '@apimmo/front/components/layouts/Grid'
import { ColumnFlex, RowFlex } from '@apimmo/front/components/layouts/GridFlex'
import { Text, TextLarge, TextSmall } from '@apimmo/front/components/Typography'
import { getNomComplet } from '@apimmo/front/utils/strings'
import React, { useEffect, useState } from 'react'
import { SyncLoader } from 'react-spinners'
import { IconCircleButton } from '@apimmo/front/components/icons/IconCircle'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'
import { CardPrimary } from '~/components/common/Card/Card'
import { CellTbody, CellThead, RowTbody, Table } from '~/components/common/Table/Table'
import { Form } from '~/components/Forms/Form'
import { SelectField } from '~/components/Forms/SelectField'
import { deleteSignature, downloadSignatureDocument, getSignerLink, listSignature, ListSignaturePayload } from '~/store/signatures/signatures.api'
import { colors } from '~/style/theme'
import { displayDateFromIso } from '~/utils/dates'
import SignatureProgressBar from './SignatureProgressBar'
import { LIST_SIGNATURE_SORT_LABEL, ListSignatureSort, SIGNATURE_TYPE_LABEL } from '~/store/signatures/signatures.constants'
import { ButtonWithIconCircle } from '~/components/common/Buttons/ButtonWithIconCircle'
import { InputField } from '~/components/Forms/InputField'
import { downloadFile } from '~/utils/file'
import DeleteSignatureModal from '../ProjetVente/DocumentsSection/DeleteSignatureModal'
import {  selectCollaborateursForPermissionAsOptions } from '~/store/collaborateurs/collaborateurs.selectors'
import { paths } from '~/routes/routes'
import { Link } from '~/components/common/Link/Link'
import { useFetchCollaborateurs } from '~/store/collaborateurs/collaborateurs.hooks'
import StatusSignatureModal from '../ProjetVente/DocumentsSection/StatusSignatureModal'

const typeOptions = Object.entries(SIGNATURE_TYPE_LABEL).map(([value, label]) => ({ label, value }))

const sortOptions = Object.entries(LIST_SIGNATURE_SORT_LABEL).map(([value, label]) => ({ label, value }))

const ListSignaturePage = () => {
  const [filters, setFilters] = useState<ListSignaturePayload>({
    page: 1,
    search: '',
    type: undefined,
    sort: ListSignatureSort.CREATED_AT_DESC,
    collaborateurId: undefined,
  })

  const fetchAllCollaborateurs = useFetchCollaborateurs()
  useEffect(() => {
    fetchAllCollaborateurs()
  }, [fetchAllCollaborateurs])

  const collaborateursOptions = useSelector(selectCollaborateursForPermissionAsOptions('showDetails'))

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>()

  const [signatures, setSignatures] = useState<any[]|undefined>()
  const [pageCount, setPageCount] = useState<number|undefined>()

  useEffect(() => {
    const execute = async () => {
      setLoading(true)
      setError(undefined)

      try {
        const result: any = await listSignature(filters)
        setSignatures(result.data)
        setPageCount(result.meta.last_page)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    execute()
  }, [filters])

  const handlePageChange = (increment: number) => {
    setFilters((filters) => ({
      ...filters,
      page: filters.page + increment,
    }))
  }

  const handleSubmit = async (
    filters: ListSignaturePayload,
  ) => {
    setFilters({
      ...filters,
      page: 1
    })
  }

  const handleDownload = async (signature: any) => {
    const blob = (await downloadSignatureDocument(signature.id) as any)
    downloadFile(signature.signedFile, blob)
  }

  const [deleting, setDeleting] = useState<Record<string, unknown>|null>(null)
  const [statusing, setStatusing] = useState<Record<string, unknown>|null>(null)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [successDelete, setSuccessDelete] = useState(false)
  const [errorDelete, setErrorDelete] = useState<unknown>()

  const handleDeleteSignature = async () => {
    setLoadingDelete(true)
    setErrorDelete(undefined)

    try {
      if (typeof deleting?.id === 'string') {
        await deleteSignature(deleting.id)
        
        setFilters({
          ...filters,
        })
      }
      setSuccessDelete(true)
    } catch (error) {
      setErrorDelete(error)
    } finally {
      setLoadingDelete(false)
    }
  }
  
  const handleClose = () => {
    setDeleting(null)
    setStatusing(null)
    setSuccessDelete(false)
    setErrorDelete(undefined)
  }

  const handleOpenSignatureLink = async (signerId: string) => {
    const result: any = await getSignerLink(signerId)

    window.open(result.link, '_blank')
  }

  return (
    <Grid gap="small">
      <CardPrimary title="Signatures">
        <Form initialValues={filters} onSubmit={handleSubmit}>
          <Grid gap="small" columns="1fr 1fr 1fr 1fr">
            <InputField
              name="search"
              label="Rechercher par titre, nom, prénom ou email."
              type="text"
              fullWidth
              icon={<Icon name="search" />}
              submitOnChange
            />
            <SelectField
              name="type"
              label="Document"
              fullWidth
              options={typeOptions}
              searchable
              clearable
              submitOnChange
            />
            <SelectField
              name="sort"
              label="Trier par date"
              fullWidth
              options={sortOptions}
              submitOnChange
            />
            <SelectField
              name="collaborateurId"
              options={collaborateursOptions}
              label="Conseiller rattaché"
              searchable
              clearable
              submitOnChange
            />
          </Grid>
        </Form>
      </CardPrimary>
      <CardPrimary>
        {loading ? (
          <ColumnFlex horizontalAlignment="center">
            <SyncLoader size={10} color={colors.primary} />
          </ColumnFlex>
        ) : null}
        {!loading && Array.isArray(signatures) && signatures.length === 0 ? (
          <TextLarge align="center">Aucune signature.</TextLarge>
        ) : null}
        {!loading &&  Array.isArray(signatures) && signatures.length > 0 ? (
          <ColumnFlex gap="medium" horizontalAlignment="center">
            <Table full>
              <thead>
                <tr>
                  <CellThead>
                    Nom du document
                  </CellThead>
                  <CellThead>
                    Destinataires
                  </CellThead>
                  <CellThead>
                    Créée par
                  </CellThead>
                  <CellThead>
                    Créée le
                  </CellThead>
                  <CellThead>
                    Statut
                  </CellThead>
                  <CellThead/>
                </tr>
              </thead>
              <tbody>
                {signatures.map((signature) => (
                  <RowTbody key={signature.id}>
                    <CellTbody>
                      <ColumnFlex horizontalAlignment="center" gap="small">
                        <TextLarge weight="bold">{signature.title}</TextLarge>
                      </ColumnFlex>
                    </CellTbody>
                    <CellTbody>
                      {signature.signers?.map((signer: any) => (
                          <Text key={signer.id}>
                            {signer.status !== 'signed' ? (
                              <IconCircleButton
                                size="tiny"
                                type="button"
                                color="transparent"
                                onClick={() => handleOpenSignatureLink(signer.id)}
                                icon="link"
                                title="Ouvrir le lien de signature"
                              />
                            ) : null}
                            {signer.contactId ? (
                              <Link
                                underline
                                size="large"
                                label={getNomComplet({ prenom: signer.firstName, nom: signer.lastName })}
                                to={generatePath(paths.showContact, { contactId: signer.contactId })}
                              />
                            ) : (
                              <>
                                {getNomComplet({ prenom: signer.firstName, nom: signer.lastName })}
                              </>
                            )}
                          </Text>
                      ))}
                    </CellTbody>
                    <CellTbody>
                      <GridCell
                        direction="column"
                        selfHorizontalAlignment="start"
                        verticalAlignment="center"
                        gap="small"
                        width={2}
                        >
                        {signature.collaborateur?.avatar ? (
                          <Avatar src={signature.collaborateur.avatar} alt={getNomComplet(signature.collaborateur)} />
                        ) : (
                          <Icon size="large" color="warning" name="user" />
                        )}
                        <TextLarge weight="semibold">
                          {getNomComplet(signature.collaborateur)}
                        </TextLarge>
                      </GridCell>
                    </CellTbody>
                    <CellTbody>
                      <Text>
                        {signature.createdAt ? displayDateFromIso(signature.createdAt) : null}
                      </Text>
                    </CellTbody>
                    <CellTbody>
                      <SignatureProgressBar signature={signature} />
                    </CellTbody>
                    <CellTbody>
                      <IconCircleButton
                        size="tiny"
                        type="button"
                        color="error"
                        onClick={() => setDeleting(signature)}
                        icon="trash"
                        title="Supprimer et annuler la signature"
                        aria-label="Supprimer et annuler la signature"
                      />
                      <IconCircleButton
                        size="tiny"
                        type="button"
                        color="transparent"
                        onClick={() => setStatusing(signature)}
                        icon="info"
                        title="Statut de la signature"
                        aria-label="Statut de la signature"
                      />
                      {signature.signedFile ? (
                          <IconCircleButton
                            size="tiny"
                            type="button"
                            color="hollow"
                            onClick={() => handleDownload(signature)}
                            icon="document"
                            aria-label="Télécharger le document signé"
                            title="Télécharger le document signé"
                          />
                        ) : null}
                    </CellTbody>
                  </RowTbody>
                ))}
              </tbody>
            </Table>
            {pageCount && pageCount > 1 ? <RowFlex
              gap="medium"
              verticalAlignment="center"
              horizontalAlignment="center"
            >
              <ButtonWithIconCircle
                onClick={() => handlePageChange(-1)}
                disabled={filters.page <= 1}
                icon="angleLeft"
              >
                Précédent
              </ButtonWithIconCircle>
              <Text>
                Page <strong>{filters.page}</strong> sur <strong>{pageCount}</strong>
              </Text>
              <ButtonWithIconCircle
                iconPosition="right"
                onClick={() => handlePageChange(+1)}
                disabled={filters.page >= pageCount}
                icon="angleRight"
              >
                Suivant
              </ButtonWithIconCircle>
            </RowFlex> : null}
          </ColumnFlex>
        ) : null}
      </CardPrimary>
      <StatusSignatureModal
        signature={statusing}
        open={Boolean(statusing)}
        onClose={handleClose}
      />
      <DeleteSignatureModal
        title={deleting?.title}
        open={Boolean(deleting)}
        loading={loadingDelete}
        success={successDelete}
        error={errorDelete}
        onClose={handleClose}
        onConfirm={handleDeleteSignature}
      />
    </Grid>
  )
}

export default ListSignaturePage
